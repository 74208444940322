/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { InfoCard } from "../Card";
import { FaqsTypeahead } from "../FaqsTypeahead";
import { getFaqData } from "../FAQSearch/utils/getFaq";

import { getFaqSubCatURL } from "../FAQSearch/utils/getFaqSubCatURL";
import { RCL as useTranslation } from "../RCL";
import { Heading } from "../Heading";
import * as styles from "./FAQHeader.module.scss";
import { Row, Cell } from "../Grid";
import { Link } from "../Link";

const FAQHeader = ({
	className,
	featured,
	categories,
	labels,
	submitHandler,
	searchParams,
	locale,
}) => {
	const viewAllLabel = useTranslation({ searchKey: "faq-view-all" }) || "View all >>";
	const emptyMessageLabel = useTranslation({
		searchKey: "faq-no-results-found",
	});

	const featuredLinks = featured?.map(feature => ({
		...feature,
		link: {
			url: getFaqSubCatURL(locale, feature?.displayTitle),
		},
	}));

	return (
		<>
			<div className={className?.backgroundWrapper}>
				<>
					<div className={className?.searchBox}>
						<Heading as="h1" className={styles.searchBoxTitle}>
							{labels && labels.title}
						</Heading>
						{categories && (
							<div className={styles.searchBoxInput}>
								<FaqsTypeahead
									className={styles.faqLandingSearch}
									data={getFaqData(categories)}
									submitHandler={submitHandler}
									searchParams={searchParams}
									locale={locale}
									emptyMessage={emptyMessageLabel}
									viewAllLink={viewAllLabel}
								/>
							</div>
						)}
					</div>
					{featuredLinks && (
						<Row className={styles?.tileSection}>
							{featuredLinks.map(featureLink => (
								<Cell key={featureLink.contentful_id} className={styles.CustomCell}>
									<Link className={styles?.link} to={featureLink?.link?.url}>
										<InfoCard header={featureLink.displayTitle} icon={featureLink.icon?.icon} />
									</Link>
								</Cell>
							))}
						</Row>
					)}
				</>
			</div>
		</>
	);
};

FAQHeader.propTypes = {
	className: PropTypes.object,
	categories: PropTypes.object.isRequired,
	featured: PropTypes.object.isRequired,
	labels: PropTypes.string.isRequired,
	submitHandler: PropTypes.func,
	searchParams: PropTypes.string,
	locale: PropTypes.string,
};

FAQHeader.defaultProps = {
	className: undefined,
	submitHandler: undefined,
	searchParams: undefined,
	locale: "en",
};

export default FAQHeader;

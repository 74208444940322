import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import { useAlerts } from "../../context";
import { BaseLayout } from "../../components/Layouts/BaseLayout";
import { FluidSections } from "../../components/FluidSections";
import FAQHeader from "../../components/FAQs/FAQHeader";
import { Section } from "../../components/Section";
import { Cell, Row } from "../../components/Grid";
import { Link } from "../../components/Link";
import { RCL as useTranslation } from "../../components/RCL";
import { CloudinaryImageAdapter } from "../../components/CloudinaryImageAdapter";
import { Img } from "../../sharedComponents/Images/Image/Img";

import * as styles from "./faq.module.scss";
import { getFaqSubCatURL } from "../../components/FAQSearch/utils/getFaqSubCatURL";

const Landing = ({ data, pageContext, location }) => {
	const [searchParams, setSearchParams] = useState("");
	const faqLabel = useTranslation({
		searchKey: "check-the-answer-of-frequently-asked-questions",
	});

	const locale = pageContext?.locale.substring(0, 2).toLowerCase();

	const { alertsCount } = useAlerts();

	const submitHandler = urlParams => {
		setSearchParams(urlParams);
		if (urlParams) navigate(`/${locale}/faqs/search/?q=${urlParams}`);
		else navigate(`/${locale}/faqs/search`);
	};

	/* Retrieve Page Data from the page query */
	const pageData = data.contentfulPage;
	const altLangPages = data.allSitePage;
	const { homepagePaths, defaultHeader, travelAlertsUrl, defaultFooter, faqsData } = data;

	let pageHeader = pageData.headerNavigation;
	let pageFooter = pageData.footer;

	if (!pageHeader) {
		pageHeader = defaultHeader;
	}

	if (!pageFooter) {
		pageFooter = defaultFooter;
	}
	const { displayTitle, featuredBackground, categories, featured } = faqsData?.edges?.[0]?.node;
	/* Use the FluidSection component to build out the fluid page sections */
	return pageData.noLayout ? (
		<FluidSections data={pageData.pageSections} location={location} locale={pageContext.locale} />
	) : (
		<BaseLayout
			location={location}
			pageContext={pageContext}
			seo={pageData.seo}
			altLangPages={altLangPages}
			footerData={pageFooter}
			headerNavigation={pageHeader}
			homepagePaths={homepagePaths}
			alerts={alertsCount}
			travelAlertsUrl={travelAlertsUrl?.path}
			pageType={pageData?.pageType}
		>
			<Section
				className={styles.faqSectionHeader}
				backgroundImage={
					<Img
						image={{
							...CloudinaryImageAdapter({
								cloudinaryImage: featuredBackground?.[0],
								maxWidth: "100%",
								aspectRatio: 1.77777,
							}),
						}}
						alt={displayTitle}
					/>
				}
			>
				<FAQHeader
					className={{ backgroundWrapper: styles?.backgroundWrapper, searchBox: styles.searchBox }}
					labels={{ title: displayTitle }}
					submitHandler={submitHandler}
					searchParams={searchParams}
					locale={locale}
					featured={featured}
					categories={categories}
				/>
			</Section>
			<Section>
				<Row className={styles?.faqRcl}>
					<Cell className={styles.fullWidthGridItem}>
						<p>{faqLabel}</p>
					</Cell>
				</Row>
				<Row cols={3}>
					{categories.map(category => (
						<Cell key={category?.displayTitle}>
							<h3 className={styles.categoryTitle}>{category.displayTitle}</h3>
							{category?.subCategories?.map(subCategory => (
								<div>
									<Link
										className={styles?.link}
										to={getFaqSubCatURL(
											pageContext?.locale?.substring(0, 2),
											subCategory.displayTitle
										)}
									>
										{subCategory.displayTitle}
									</Link>
								</div>
							))}
						</Cell>
					))}
				</Row>
			</Section>
			{pageData?.sections && (
				<FluidSections
					data={pageData.pageSections}
					location={location}
					locale={pageContext.locale}
				/>
			)}
		</BaseLayout>
	);
};

/* Declare page prop */
Landing.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default Landing;

/* Query page data using the pageSections GraphQL fragment included in the imported queryFluidSection file */
export const pageQuery = graphql`
	query GetLandingLayoutPageData(
		$id: String!
		$locale: String!
		$rclLocale: String!
		$ctf_id: String!
	) {
		contentfulPage(id: { eq: $id }) {
			title
			pageType
			sections {
				__typename
				...Collection
				...ComponentBlock
				...HardCodedComponent
				...LinkWithModal
				...ListLink
				...MultiImageBlock
				...Paragraph
				...PromotionRule
				...Section
				...SplitContentWrapper
				...TabsAndAccordion
				...TimedComponent
				...VideoPlayer
			}
			seo {
				...SEO
			}
			footer {
				...Footer
			}
			headerNavigation {
				...headerNavigation
			}
		}
		faqsData: allContentfulFaq(
			filter: {
				node_locale: { eq: $rclLocale }
				application: { elemMatch: { application3charCode: { eq: "SOV" } } }
			}
		) {
			edges {
				node {
					...faqsWithQAs
				}
			}
		}
		homepagePaths: allSitePage(filter: { context: { template: { eq: "homepage" } } }) {
			edges {
				...homepagePaths
			}
		}
		allSitePage(filter: { context: { ctf_id: { eq: $ctf_id } } }) {
			edges {
				...LanguageToggle
			}
		}
		defaultHeader: contentfulHeaderNavigation(
			contentful_id: { eq: "4WLelYKXDOXBf7CGGtf52z" }
			node_locale: { eq: $locale }
		) {
			...headerNavigation
		}
		defaultFooter: contentfulFooter(
			contentful_id: { eq: "3TjYjWvnKS88bdse66t4oo" }
			node_locale: { eq: $locale }
		) {
			...Footer
		}
		travelAlertsUrl: sitePage(context: { template: { eq: "alerts" }, locale: { eq: $locale } }) {
			path
		}
	}
`;
